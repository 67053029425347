import  { useEffect, useState } from 'react';
import { Tabs, Group, Button, TextInput, FileInput, Avatar,  Card, Container, Notification, Loader, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconUpload, IconEdit, IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { isErrorObject } from '../utils/utils';
import { makeGetRequest, makePutRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';
import { isEmpty } from '../utils/FormValidator';
import { IUser } from '../types';



// User Profile Component
const UserProfilePage = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading,setIsloading] = useState<boolean>(false);
  const [userProfile,setUserProfile] = useState<IUser>();

  
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword:''
});
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword:''
});



  const user = JSON.parse(localStorage.getItem('user') || '{}');


  // Form handling with mantine's useForm
  const userForm = useForm({
    initialValues: {
      firstName: userProfile?.first_name || user.first_name,
      lastName: userProfile?.last_name || user.last_name,
      email: userProfile?.email||user.email,
      phone: userProfile?.mobile_number || user.mobile_number,
      avatar: userProfile?.avatar || '',
    },
  });

  const organizationForm = useForm({
    initialValues: {
      name: userProfile?.organization_name || '',
      type: userProfile?.org_type || '',
      regNumber: userProfile?.reg_number || '',
      country: userProfile?.country || '',
      authorizedPersonName: user?.authorized_person || '',
      position: user?.authorized_position || '',
      regCertificate: user?.reg_certificate || '',
      isOrganization: true,
    },
  });

  const handleEditMode=()=>{
    setEditMode(true)


  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
        ...values,
        [e.target.name]: e.target.value
    });
}

  // Handle profile save
  const handleSaveUserProfile = async() => {

   // e.preventDefault();
    setIsSubmitting(true)

    


    const res = await makePutRequest(`user/${user.id}`,userForm.values);
    if (isErrorObject(res)) {
      setIsSubmitting(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
      setSuccess(false)
  } else {
            
      setIsSubmitting(false);
      setError(null);
      setSuccess(true)
          // Handle save logic here
    console.log('User Data', userForm.values);
    fetchUser();
    setEditMode(false);
  
  }


  };

  const handleSaveOrgaProfile = async() => {

    //e.preventDefault();
    setIsSubmitting(true)



    const res = await makePutRequest(`user/${user.id}`,organizationForm.values);
    if (isErrorObject(res)) {
      setIsSubmitting(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
      setSuccess(false)
  } else {
            
      setIsSubmitting(false);
      setError(null);
      setSuccess(true)
         // Handle save logic here
    fetchUser();
    setEditMode(false);
  
  }

 
  };

  const validatateChangePassword =(inputData:any)=>{
    let errors = {
      currentPassword:'',
      newPassword:'',
      confirmNewPassword:''
     
  };
  if(isEmpty(inputData.currentPassword.trim())){
    errors.currentPassword='Enter current password';
}
if(isEmpty(inputData.newPassword.trim())){
  errors.newPassword='Enter new password'

}

if(isEmpty(inputData.confirmNewPassword.trim())){
  errors.confirmNewPassword='Confirm new password password'


}
if(inputData.newPassword !== inputData.confirmNewPassword){
  errors.newPassword='Your new password did not match';
}
return errors;



  }

  const changePassword = async() => {
  
    setErrors(validatateChangePassword(values))

   

    const data ={
      currentPassword:values.currentPassword,
      newPassword:values.newPassword,
      confirmPassword:values.confirmNewPassword
    }

   
    setIsSubmitting(true)
    try{
      const res = await makePutRequest(`user/${user.id}/change-password`,data);
      if (isErrorObject(res)) {
        setIsSubmitting(false);
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
        setSuccess(false)
    } else {
              
        setIsSubmitting(false);
        setError(null);
        setSuccess(true)
          
    
    }
  

    }catch(err){
      console.log(err)
      setIsSubmitting(false)

    }


   
 
  };


  const fetchUser = async () => {
    setIsloading(true);
    const res = await makeGetRequest(`user/${user.id}`);
  
    if (isErrorObject(res)) {
      setIsloading(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      const results = res.data;
      setUserProfile(results);
      setIsloading(false);
      setError(null);
    }
  };

  useEffect(()=>{

    fetchUser();

  },[])

  return (
   <Container>
    {isLoading && <Loader/>}
     <Card shadow="sm" padding="lg">
     {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    Profile Updated
                </Notification>
            )}
      <Tabs defaultValue="user" variant="outline">
        {/* User Tab */}
        <Tabs.List>
          <Tabs.Tab value="user">User</Tabs.Tab>
          <Tabs.Tab value="organization" disabled={user.account_type === 'individual'}>
            Organization
          </Tabs.Tab>

          <Tabs.Tab value="password">Change Password</Tabs.Tab>

        </Tabs.List>

        {/* User Information Tab */}
        <Tabs.Panel value="user" pt="xs">
          <Group position="center" mb="lg">
            <Avatar size={120} src={userForm.values.avatar} />
          </Group>

          <form onSubmit={userForm.onSubmit(() => handleSaveUserProfile())}>
            <TextInput
              label="First Name"
              placeholder="Enter first name"
              {...userForm.getInputProps('firstName')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Last Name"
              placeholder="Enter last name"
              {...userForm.getInputProps('lastName')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Email"
              placeholder="Enter email"
              {...userForm.getInputProps('email')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Phone"
              placeholder="Enter phone number"
              {...userForm.getInputProps('phone')}
              disabled={!editMode}
              mb="sm"
            />
            <FileInput
              label="Avatar"
              placeholder="Select an image"
              icon={<IconUpload size={14} />}
              {...userForm.getInputProps('avatar')}
              disabled={!editMode}
              mb="sm"
            />

            <Group position="right" mt="md">
              {!editMode ? (
                <Button leftIcon={<IconEdit />} onClick={() => handleEditMode()}>
                  Edit Profile
                </Button>
              ) : (
                <Button type="submit" color="green">
                  {isSubmitting ? <Loader/> :'Save Changes'}
                </Button>
              )}
            </Group>
          </form>
        </Tabs.Panel>

        {/* Organization Information Tab */}
        <Tabs.Panel value="organization" pt="xs">
          <form onSubmit={organizationForm.onSubmit(() => handleSaveOrgaProfile())}>
            <TextInput
              label="Organization Name"
              placeholder="Enter organization name"
              {...organizationForm.getInputProps('name')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Organization Type"
              placeholder="Enter organization type"
              {...organizationForm.getInputProps('type')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Registration Number"
              placeholder="Enter registration number"
              {...organizationForm.getInputProps('regNumber')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Country"
              placeholder="Enter country"
              {...organizationForm.getInputProps('country')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Authorized Person Name"
              placeholder="Enter authorized person's name"
              {...organizationForm.getInputProps('authorizedPersonName')}
              disabled={!editMode}
              mb="sm"
            />
            <TextInput
              label="Position"
              placeholder="Enter position"
              {...organizationForm.getInputProps('position')}
              disabled={!editMode}
              mb="sm"
            />
            <FileInput
              label="Registration Certificate"
              placeholder="Upload PDF/Image"
              icon={<IconUpload size={14} />}
              {...organizationForm.getInputProps('regCertificate')}
              disabled={!editMode}
              mb="sm"
            />

            <Group position="right" mt="md">
              {!editMode ? (
                <Button leftIcon={<IconEdit />} onClick={() => setEditMode(true)}>
                  Edit Organization
                </Button>
              ) : (
                <Button type="submit" color="green">
                  {isSubmitting ? <Loader/> :'Save Changes'}
                </Button>
              )}
            </Group>
          </form>
        </Tabs.Panel>


         {/* Password Tab */}
         <Tabs.Panel value="password" pt="xs">
          <form onSubmit={organizationForm.onSubmit(() => changePassword())}>
         

<PasswordInput label="Current Password" name="currentPassword" placeholder="Enter your current password"  mt="md" onChange={handleChange}/>
{errors.currentPassword && <p className='error'>{errors.currentPassword}</p>}

<PasswordInput label="New Password" name="newPassword" placeholder="Enter your new password"  mt="md" onChange={handleChange}/>
{errors.newPassword && <p className='error'>{errors.newPassword}</p>}

<PasswordInput label="Confirm Password" name="confirmNewPassword" placeholder="Confirm New Passowrd"  mt="md" onChange={handleChange}/>
{errors.confirmNewPassword && <p className='error'>{errors.confirmNewPassword}</p>}



           

            <Group position="right" mt="md">
             
                <Button type="submit" color="green">
                  {isSubmitting ? <Loader/> :'Change Password'}
                </Button>
              
            </Group>
          </form>
        </Tabs.Panel>
      </Tabs>
    </Card>
   </Container>
  );
};

export default UserProfilePage;
