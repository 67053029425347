import { useEffect, useState } from 'react';
import { Box, Button, Modal, Notification, Group, Text, Avatar, Loader } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IconCheck, IconAlertCircle, IconPlus, IconEdit, IconTrash, IconMessage } from '@tabler/icons-react';
import { makeGetRequest, makeDeleteRequest } from '../services/httpHelper';
import { ICampaignAdmin } from '../types';
import AddAdminForm from '../components/AddAdminForm';
import EditAdminForm from '../components/EditAdminForm';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import MessageAdmin from './MessageAdmin';

const PAGE_SIZE = 10;

const ManageAdminPage = () => {
    const [admins, setAdmins] = useState<ICampaignAdmin[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedAdmin, setSelectedAdmin] = useState<ICampaignAdmin | null>(null);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<ICampaignAdmin[]>([]);

    const{id} = useParams()||''
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const navigate = useNavigate();

    useEffect(() => {

        const fetchCampaign = async () => {
            const res = await makeGetRequest( `campaign/${id}`);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
              } else {
                if(res.data.user_id !== user.id){
                  navigate('/unauthorized');
                  
                }
            }
        };
        const fetchAdmins = async () => {
            const res = await makeGetRequest(`campaign-admins/${id}`);
            setIsLoading(false);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setAdmins(res.data);
                setRecords(res.data.slice(0, PAGE_SIZE));
            }
        };
        fetchCampaign();
        fetchAdmins();
    }, []);

    const handlePageChange = (page: number) => {
        setPage(page);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(admins.slice(from, to));
    };

    const handleEditAdmin = (admin: ICampaignAdmin) => {
        setSelectedAdmin(admin);
        setShowEditModal(true);
    };

    const handleRemoveAdmin = async (adminId: string) => {
        const res = await makeDeleteRequest(`campaign-admin/${adminId}`);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setSuccess('Admin removed successfully.');
            setAdmins(admins.filter(admin => admin.id !== adminId));
            setRecords(records.filter(admin => admin.id !== adminId));
        }
    };

    const handleAdminDetails = (admin: ICampaignAdmin) => {
        setSelectedAdmin(admin);
        // Logic to show admin details can go here, either a modal or a new page
      setShowMessageModal(true)
    };

   

    return (
        <Box mx="auto" p="md">
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    {success}
                </Notification>
            )}

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Group position="apart" mb="md">
                        <Text weight={500} size="lg">
                            Manage Campaign Admins
                        </Text>
                        <Button leftIcon={<IconPlus />} onClick={() => setShowAddModal(true)}>
                            Add Admin
                        </Button>
                    </Group>

                    <DataTable
                       columns={[
                        {
                            accessor: 'name',
                            render: ({ user }: ICampaignAdmin) => (
                                <Group>
                                    <Avatar size="sm" radius="xl" />
                                    <Text>{`${user.first_name} ${user.last_name}`}</Text>
                                </Group>
                            ),
                        },
                        {
                            accessor: 'email',
                            render: ({ user }: ICampaignAdmin) => <Text>{user.email}</Text>,
                        },
                        {
                            accessor: 'mobile_number',
                            render: ({ user }: ICampaignAdmin) => <Text>{user.mobile_number}</Text>,
                        },
                        {
                            accessor: 'role',
                            render: ({ role }: ICampaignAdmin) => <Text>{role}</Text>, // Adjust according to your role property
                        },
                        {
                            accessor: 'actions',
                            render: (admin: ICampaignAdmin) => (
                                <Group spacing="sm">
                                    <Button
                                        leftIcon={<IconEdit size={16} />}
                                        size="xs"
                                        onClick={() => handleEditAdmin(admin)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        leftIcon={<IconTrash size={16} />}
                                        size="xs"
                                        color="red"
                                        onClick={() => handleRemoveAdmin(admin.id)}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        leftIcon={<IconMessage size={16} />}
                                        size="xs"
                                        onClick={() => handleAdminDetails(admin)}
                                    >
                                        Message Admin
                                    </Button>
                                </Group>
                            ),
                        },
                    ]}
                        records={records}
                        totalRecords={admins.length}
                        recordsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handlePageChange}
                        highlightOnHover
                        verticalSpacing="sm"
                    />

                    {/* Modals for adding and editing admins */}
                    <Modal opened={showAddModal} onClose={() => setShowAddModal(false)} title="Add Admin">
                        <AddAdminForm campaignId={id}/>
                    </Modal>

                    <Modal opened={showMessageModal} onClose={() => setShowMessageModal(false)} title="Message Admin">
                        <MessageAdmin admin={selectedAdmin as ICampaignAdmin}/>
                    </Modal>

                    {selectedAdmin && (
                        <Modal opened={showEditModal} onClose={() => setShowEditModal(false)} title="Edit Admin">
                            <EditAdminForm admin={selectedAdmin} />
                        </Modal>
                    )}
                </>
            )}
        </Box>
    );
};

export default ManageAdminPage;
