import {
    Anchor,
    Button,
    Container,
    Divider,
    Group,
    Loader,
    Paper,
    PasswordInput,
    Radio,
    Text,
    TextInput,
    Title,
    Select,
    
} from '@mantine/core';
import { DateInput } from '@mantine/dates';

import { Helmet } from "react-helmet";
import { IconBrandFacebook, IconBrandGoogle } from "@tabler/icons-react";
import { useState } from 'react';
import { isEmpty } from '../utils/FormValidator';
import { makePostRequest } from '../services/httpHelper';
import { Navigate } from 'react-router-dom';
import { BASE_URL } from '../services/paths';
import { CountrySelect } from '../components';
import { Link } from 'react-router-dom';

const SignupPage = () => {
    const [values, setValues] = useState({
        email: '',
        mobile_number: '',
        password: '',
        password_confirm: '',
        first_name: '',
        last_name: '',
        date_of_birth: null as Date | null,
        orgName: '',
        taxId: '',
        regNumber: '',
        authorizedPerson: '',
        authorizedPosition: '',
        orgType: '' as string | null,
        accountType: 'individual',
    });

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password2: '',
        mobile_number: '',
        date_of_birth: '',
        orgName: '',
        taxId: '',
        regNumber: '',
        authorizedPerson: '',
        authorizedPosition: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryError,setSelectedCountryError]= useState('')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    function calculateAge(dateOfBirth: Date | null): number {
        if (!dateOfBirth) return 0; // Handle null case
      
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
      
        // Adjust age if the birth month hasn't passed yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        
        return age;
      }

    const handleSignup = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);

        const age = values.date_of_birth ? calculateAge(values.date_of_birth) : 0;

        if (age < 18) {
            setErrors({ ...errors, date_of_birth: 'You must be at least 18 years old to sign up.' });
            setIsSubmitting(false);
            return;
        }
        if(!selectedCountry){
            setSelectedCountryError('Pick your country')
            return;
        }

        const data = {
            mobile_number: values.mobile_number,
            password: values.password,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            date_of_birth: values.date_of_birth,
            country: selectedCountry,
            ...(values.accountType === 'organization' && {
                organization_name: values.orgName,
                org_type: values.orgType,
                tax_id: values.taxId,
                reg_number: values.regNumber,
                authorized_person: values.authorizedPerson,
                authorized_position: values.authorizedPosition,
            }),
        };

        if (Object.values(errors).some(err => !isEmpty(err))) {
            setIsSubmitting(false);
            return;
        }

        const res = await makePostRequest('auth/signup', data);

        if (res && res.errorMessage) {
            setIsSubmitting(false);
            setError(res.errorMessage);
        } else {
            setIsSuccess(true);
            setIsSubmitting(false);
            setError(null);
        }
    };

    const handleCountryChange = (selectedCountry: string) => {
        setSelectedCountry(selectedCountry);

        if (selectedCountry !== '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                ['country']: ''
            }));
        }
    };

    const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 17);

    return (
        <>
            <Helmet>
                <title>Signup</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title align="center" sx={() => ({ fontWeight: 900 })}>
                    Welcome back!
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                    Already have an account?{' '}
                    <Link to="/login">
                        <Anchor size="sm" component="button">
                            Login
                        </Anchor>
                    </Link>
                </Text>

                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <Group grow mb="md" mt="md">
                        <Button radius="xl" leftIcon={<IconBrandFacebook size={18} />} onClick={() => window.location.href = `${BASE_URL}auth/facebook`}>Facebook</Button>
                        <Button radius="xl" leftIcon={<IconBrandGoogle size={18} />} onClick={() => window.location.href = `${BASE_URL}auth/google`}>Google</Button>
                    </Group>
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />
                    {isSubmitting && <Loader />}
                    {isSuccess && <Navigate to={`/verify-email/${values.email}`} />}
                    {error && <p className='error'>{error}</p>}

                    <Group>
                        <Radio
                            label="Individual"
                            value="individual"
                            checked={values.accountType === 'individual'}
                            onChange={() => setValues({ ...values, accountType: 'individual' })}
                        />
                        <Radio
                            label="Organization"
                            value="organization"
                            checked={values.accountType === 'organization'}
                            onChange={() => setValues({ ...values, accountType: 'organization' })}
                        />
                    </Group>

                    <TextInput label="First Name" placeholder="First Name" name="first_name" onChange={handleChange} value={values.first_name} />
                    {!isEmpty(errors.first_name) && <p className='error'>{errors.first_name}</p>}
                    <TextInput label="Last Name" placeholder="Last Name" name="last_name" onChange={handleChange} value={values.last_name} />
                    {!isEmpty(errors.last_name) && <p className='error'>{errors.last_name}</p>}
                    <TextInput label="Email" placeholder="Your Email" name="email" onChange={handleChange} value={values.email} mt="md" />
                    {!isEmpty(errors.email) && <p className='error'>{errors.email}</p>}
                    <TextInput label="Phone" placeholder="Your Mobile Phone" name="mobile_number" onChange={handleChange} value={values.mobile_number} mt="md" />
                    {!isEmpty(errors.mobile_number) && <p className='error'>{errors.mobile_number}</p>}
                    <CountrySelect value={selectedCountry} onChange={handleCountryChange} />
                    {selectedCountryError && <p className='error'>{selectedCountryError}</p>}

                    <br/><br/>

                    <DateInput
                    value={values.date_of_birth}
                    onChange={(date) => setValues({ ...values, date_of_birth: date })} 
                    label="Date of Birth"
                    placeholder="Picke Date Of Birth"
                    maxDate={new Date}
                    minDate={new Date(1940, 0, 1)}
                    clearable 
                    defaultValue={maxDate}
                    />

                 
                    {!isEmpty(errors.date_of_birth) && <p className='error'>{errors.date_of_birth}</p>}

                    <PasswordInput label="Password" placeholder="Password" name="password" onChange={handleChange} value={values.password} mt="md" />
                    {!isEmpty(errors.password) && <p className='error'>{errors.password}</p>}

                    <PasswordInput label="Confirm Password" placeholder="Confirm Password" name="password2" onChange={handleChange} mt="md" />
                    {!isEmpty(errors.password2) && <p className='error'>{errors.password2}</p>}

                    {values.accountType === 'organization' && (
                        <>
                            <TextInput label="Organization Name" placeholder="Organization Name" name="orgName" onChange={handleChange} value={values.orgName} mt="md" />
                            {!isEmpty(errors.orgName) && <p className='error'>{errors.orgName}</p>}
                            <Select
                                label="Organization Type"
                                placeholder="Select organization type"
                                name="orgType"
                                value={values.orgType}
                                onChange={(value) => setValues({ ...values, orgType: value })}
                                mt="md"
                                data={[
                                    { value: 'charity', label: 'Charity' },
                                    { value: 'church', label: 'Church' },
                                    { value: 'company', label: 'Company' },
                                    { value: 'non-profit', label: 'Non-Profit' },
                                ]}
                            />
                            <TextInput label="Tax ID" placeholder="Tax ID" name="taxId" onChange={handleChange} value={values.taxId} mt="md" />
                            {!isEmpty(errors.taxId) && <p className='error'>{errors.taxId}</p>}
                            <TextInput label="Registration Number" placeholder="Registration Number" name="regNumber" onChange={handleChange} value={values.regNumber} mt="md" />
                            {!isEmpty(errors.regNumber) && <p className='error'>{errors.regNumber}</p>}
                            <TextInput label="Authorized Person" placeholder="Authorized Person" name="authorizedPerson" onChange={handleChange} value={values.authorizedPerson} mt="md" />
                            {!isEmpty(errors.authorizedPerson) && <p className='error'>{errors.authorizedPerson}</p>}
                            <TextInput label="Authorized Position" placeholder="Authorized Position" name="authorizedPosition" onChange={handleChange} value={values.authorizedPosition} mt="md" />
                            {!isEmpty(errors.authorizedPosition) && <p className='error'>{errors.authorizedPosition}</p>}
                        </>
                    )}

                    <Button fullWidth mt="xl" size="md" onClick={handleSignup} disabled={isSubmitting}>
                        Sign Up
                    </Button>
                </Paper>
            </Container>
        </>
    );
};

export default SignupPage;
