import {
    Box,
    Button,
    Card,
    Container,
    createStyles,
    Flex,
    Group,
    Loader,
    Paper,
    PaperProps,
    rem,
    SimpleGrid,
    Stack,
    Text,
    Title,
    TitleProps
} from "@mantine/core";
import {
    IconArrowDownRight,
    IconArrowUpRight,
    IconFunction,
    IconPlus,
    IconReceipt2,
    IconTrophy
} from "@tabler/icons-react";
import {CampaignsTable, DonatorsTable, YearlyDonationChart} from "../components";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { useEffect, useState } from "react";
import ErrorHandler from "../services/ErrorHandler";
import { makeGetRequest } from "../services/httpHelper";
import { ICampaign, IDonation, IDonationByCategory } from "../types";
import { formatCurrency } from "../utils/FormValidator";

const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    value: {
        fontSize: rem(24),
        fontWeight: 700,
        lineHeight: 1,
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));

type DonationSummary = {
    todayTotal: number;
    yesterdayTotal: number;
    percentageDifference: number;
  };

  type AverageDonationSummary = {
    currentMonthAverage: number;
    previousMonthAverage: number;
    percentageDifference: number;
  };

  type CampaignSummary = {
    currentMonthTotal: number;
    previousMonthTotal: number;
    percentageDifference: number;
  };

const DashboardPage = () => {
    const {classes} = useStyles();

    const paperProps: PaperProps = {
        p: "md",
        shadow: "sm"
    }

    const subTitleProps: TitleProps = {
        size: 18,
        mb: "sm"
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [donationsByCats, setDonationsByCats] = useState<IDonationByCategory[]>([]);
    const [donations, setDonations] = useState<IDonation[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState<DonationSummary | null>(null);
    const [averageSummary, setAverageSummary] = useState<AverageDonationSummary | null>(null);
    const [campaignSummary, setCampaignSummary] = useState<CampaignSummary | null>(null);





    const totalDonations =():number=>{
        let total:number = 0;

        donations.map((donation:IDonation)=>{
            total = total+parseFloat(donation.donation_amount)
        })
        return total;

    }

    const calculateDonations = (donations: IDonation[]): DonationSummary => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
      
        const isSameDay = (date1: Date, date2: Date) => 
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate();
      
        const todayTotal = donations
          .filter(donation => isSameDay(new Date(donation.createdAt), today))
          .reduce((total, donation) => total + parseFloat(donation.donation_amount), 0);
      
        const yesterdayTotal = donations
          .filter(donation => isSameDay(new Date(donation.createdAt), yesterday))
          .reduce((total, donation) => total + parseFloat(donation.donation_amount), 0);
      
        const percentageDifference = yesterdayTotal === 0 
          ? (todayTotal === 0 ? 0 : 100)
          : ((todayTotal - yesterdayTotal) / yesterdayTotal) * 100;
      
        return {
          todayTotal,
          yesterdayTotal,
          percentageDifference
        };
      };

      const calculateAverageDonationsPerCampaign = (donations: IDonation[]): AverageDonationSummary => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
      
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
        const filterByMonth = (date: Date, month: number, year: number) =>
          date.getMonth() === month && date.getFullYear() === year;
      
        const currentMonthDonations = donations.filter(donation =>
          filterByMonth(new Date(donation.createdAt), currentMonth, currentYear)
        );
      
        const previousMonthDonations = donations.filter(donation =>
          filterByMonth(new Date(donation.createdAt), previousMonth, previousMonthYear)
        );
      
        const calculateAverage = (donations: IDonation[]) => {
          const totalDonations = donations.reduce((total, donation) => total + parseFloat( donation.donation_amount), 0);
          const numberOfCampaigns = new Set(donations.map(donation => donation.campaign_id)).size;
          return numberOfCampaigns === 0 ? 0 : totalDonations / numberOfCampaigns;
        };
      
        const currentMonthAverage = calculateAverage(currentMonthDonations);
        const previousMonthAverage = calculateAverage(previousMonthDonations);
      
        const percentageDifference = previousMonthAverage === 0 
          ? (currentMonthAverage === 0 ? 0 : 100)
          : ((currentMonthAverage - previousMonthAverage) / previousMonthAverage) * 100;
      
        return {
          currentMonthAverage,
          previousMonthAverage,
          percentageDifference
        };
      };

      const calculateCampaignSummary = (campaigns: ICampaign[]): CampaignSummary => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
      
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
        const filterByMonth = (date: Date, month: number, year: number) =>
          date.getMonth() === month && date.getFullYear() === year;
      
        const currentMonthCampaigns = campaigns.filter(campaign =>
          filterByMonth(new Date(campaign.createdAt), currentMonth, currentYear)
        );
      
        const previousMonthCampaigns = campaigns.filter(campaign =>
          filterByMonth(new Date(campaign.createdAt), previousMonth, previousMonthYear)
        );
      
        const currentMonthTotal = currentMonthCampaigns.length;
        const previousMonthTotal = previousMonthCampaigns.length;
      
        const percentageDifference = previousMonthTotal === 0 
          ? (currentMonthTotal === 0 ? 0 : 100)
          : ((currentMonthTotal - previousMonthTotal) / previousMonthTotal) * 100;
      
        return {
          currentMonthTotal,
          previousMonthTotal,
          percentageDifference
        };
      };

    useEffect(() => {
        const fetchCampaigns = async () => {
            setIsLoading(true);
            const endpoint = 'campaigns';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setCampaigns(res.data);
            }
            setIsLoading(false);
        };


        const fetchDonationsByCats = async () => {
            setIsLoading(true);
            const endpoint = 'campaign-category-donations';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setDonationsByCats(res.data);
            }
            setIsLoading(false);
        };

        const fetchDonations = async () => {
            setIsLoading(true);
            const endpoint = 'campaign-donations';
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setDonations(res.data);
            }
            setIsLoading(false);
        };

        fetchCampaigns();
        fetchDonationsByCats();
        fetchDonations();

      
    }, []);

    useEffect(() => {
        const summary = calculateDonations(donations);
        setSummary(summary);

        const avSummary = calculateAverageDonationsPerCampaign(donations);
        setAverageSummary(avSummary);
        const campSummary = calculateCampaignSummary(campaigns);
        setCampaignSummary(campSummary);

      }, [donations,campaigns]);



        return (
            <>
            {isLoading && <Loader/>}
            {error && <p className="error">{error}</p>}
                <Helmet>
                    <title>Admin Dashboard</title>
                </Helmet>
                <Box>
                    <Container fluid my="xl">
                        <Stack spacing="xl">
                            <Title order={3}>Welcome Again, {toTitleCase(user.first_name)}</Title>
                            <SimpleGrid
                                cols={4}
                                breakpoints={[{maxWidth: 'md', cols: 2, spacing: 'md'}, {
                                    maxWidth: 'sm',
                                    cols: 1,
                                    spacing: 'sm'
                                }]}
                            >
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Total Donations
                                        </Text>
                                        <IconReceipt2 className={classes.icon} size="1.4rem" stroke={1.5}/>
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>${formatCurrency(totalDonations())}</Text>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                       {/* <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>10%</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5}/>
                                        </Text>*/}
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Donations todate
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Today's Donations
                                        </Text>
                                        <IconReceipt2 className={classes.icon} size="1.4rem" stroke={1.5}/>
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>${summary && summary.todayTotal.toFixed(2)}</Text>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                        <Text color={summary && summary.percentageDifference > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>{summary && summary.percentageDifference.toFixed(2)}%</span>
                                            <IconArrowDownRight size="1rem" stroke={1.5}/>
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to yesterday
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Average Donations per Campaign
                                        </Text>
                                        <IconFunction className={classes.icon} size="1.4rem" stroke={1.5}/>
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>{averageSummary && averageSummary.currentMonthAverage.toFixed(2)}%</Text>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                        <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>{averageSummary && averageSummary.percentageDifference.toFixed(2)}%</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5}/>
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to previous month
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Active Campaigns
                                        </Text>
                                        <IconTrophy className={classes.icon} size="1.4rem" stroke={1.5}/>
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>{campaignSummary && campaignSummary.currentMonthTotal}</Text>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                        <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>{campaignSummary && campaignSummary.percentageDifference.toFixed(2)}%</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5}/>
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to previous month
                                    </Text>
                                </Paper>
                            </SimpleGrid>
                            <Paper {...paperProps}>
                                <Card.Section mb="lg">
                                    <Flex align="center" justify="space-between">
                                        <Box>
                                            <Title {...subTitleProps}>Campaigns</Title>
                                            <Text size="sm">Let&apos;s manage your campaigns</Text>
                                        </Box>
                                        <Button
                                            leftIcon={<IconPlus size={18}/>}
                                            component={Link}
                                            to="/create-campaign"
                                        >
                                            Create a Campaign
                                        </Button>
                                    </Flex>
                                </Card.Section>
                                <Card.Section>
                                    <CampaignsTable campaigns={campaigns}/>
                                </Card.Section>
                            </Paper>
                            <Paper {...paperProps}>
                                <Card.Section>
                                    <Title {...subTitleProps}>Top Contributors</Title>
                                    { totalDonations()>0 ?
                                        <DonatorsTable campaigns={campaigns}/>
                                        :
                                        <Text>No donations</Text>
                                    }
                                   
                                </Card.Section>
                                <Card.Section></Card.Section>
                            </Paper>
                            <Paper {...paperProps}>
                                <Title {...subTitleProps}>Donations per Category</Title>
                                <YearlyDonationChart donations={donationsByCats}/>
                            </Paper>
                        </Stack>
                    </Container>
                </Box>
            </>
        );
      }

   


export default DashboardPage;
