import { useEffect, useState } from 'react';
import { Box, Button, Modal, Notification, Group, Text, Avatar, Loader } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { IconCheck, IconAlertCircle, IconPlus, IconEdit, IconTrash, IconMessage } from '@tabler/icons-react';
import { makeGetRequest, makeDeleteRequest } from '../services/httpHelper';
import { ICampaignAdmin, ICampaignTeam } from '../types';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import MessagePromoter from './MessagePromoter';
import AddCampaignPromoterForm from '../components/AddCampaignPromoterForm';

const PAGE_SIZE = 10;

const ManageTeamPage = () => {
    const [teams, setTeams] = useState<ICampaignTeam[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
   // const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<ICampaignTeam | null>(null);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<ICampaignTeam[]>([]);
    const [admins, setAdmins] = useState<ICampaignAdmin[]>([]);


    const{id} = useParams()||''

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') || '{}');


   

    const handlePageChange = (page: number) => {
        setPage(page);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(teams.slice(from, to));
    };

    const handleEditAdmin = (admin: ICampaignTeam) => {
        setSelectedTeam(admin);
      //  setShowEditModal(true);
      alert('Not implmented')
    };

    const handleRemovePromoter = async (teamId: string) => {
        const confirm = window.confirm('Are sure you want to remove this promoter?')
      if(confirm){
        const res = await makeDeleteRequest(`team/${teamId}`);

        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setSuccess('Promoter removed successfully.');
            setTeams(teams.filter(admin => admin.id !== teamId));
            setRecords(records.filter(admin => admin.id !== teamId));
        }
      }
    };

    const handleAdminDetails = (team: ICampaignTeam) => {
        setSelectedTeam(team);
        // Logic to show admin details can go here, either a modal or a new page
      setShowMessageModal(true)
    };

    const fetchAdmins = async () => {
        const res = await makeGetRequest(`campaign-admins/${id}`);
        setIsLoading(false);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setAdmins(res.data);
        }
    };

    const userHasAccess =()=>{
        if(user.id===id){
          return true;
      
        }
        if(admins.length){
          const inAdmins = admins.filter((admin)=>admin.user.id===user.id);
          if(inAdmins){
            return true
          }
        
         
        }
         return false;
      
      }


    useEffect(() => {
        const fetchPromoters = async () => {
            const res = await makeGetRequest(`teams/${id}`);
            setIsLoading(false);
            if (isErrorObject(res)) {
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setTeams(res.data);
                setRecords(res.data.slice(0, PAGE_SIZE));
            }
        };
        fetchAdmins();
        fetchPromoters();
        const hasAccess= userHasAccess();
        if(!hasAccess){
            navigate('/unauthorized')
        }
    }, []);

   

    return (
        <Box mx="auto" p="md">
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    {success}
                </Notification>
            )}

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Group position="apart" mb="md">
                        <Text weight={500} size="lg">
                            Manage Campaign Promoters
                        </Text>
                        <Button leftIcon={<IconPlus />} onClick={() => setShowAddModal(true)}>
                            Add Promoter
                        </Button>
                    </Group>

                    <DataTable
                       columns={[
                        {
                            accessor: 'name',
                            render: ({ user }: ICampaignTeam) => (
                                <Group>
                                    <Avatar size="sm" radius="xl" />
                                    <Text>{`${user.first_name} ${user.last_name}`}</Text>
                                </Group>
                            ),
                        },
                        {
                            accessor: 'email',
                            render: ({ user }: ICampaignTeam) => <Text>{user.email}</Text>,
                        },
                        {
                            accessor: 'mobile_number',
                            render: ({ user }: ICampaignTeam) => <Text>{user.mobile_number}</Text>,
                        },
                        {
                            accessor: 'role',
                            render: ({ role }: ICampaignTeam) => <Text>{role}</Text>, // Adjust according to your role property
                        },
                        {
                            accessor: 'actions',
                            render: (admin: ICampaignTeam) => (
                                <Group spacing="sm">
                                    <Button
                                        leftIcon={<IconEdit size={16} />}
                                        size="xs"
                                        onClick={() => handleEditAdmin(admin)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        leftIcon={<IconTrash size={16} />}
                                        size="xs"
                                        color="red"
                                        onClick={() => handleRemovePromoter(admin.id)}
                                    >
                                        Remove
                                    </Button>
                                    <Button
                                        leftIcon={<IconMessage size={16} />}
                                        size="xs"
                                        onClick={() => handleAdminDetails(admin)}
                                    >
                                        Message Promoter
                                    </Button>
                                </Group>
                            ),
                        },
                    ]}
                        records={records}
                        totalRecords={teams.length}
                        recordsPerPage={PAGE_SIZE}
                        page={page}
                        onPageChange={handlePageChange}
                        highlightOnHover
                        verticalSpacing="sm"
                    />

                    {/* Modals for adding and editing teams */}
                    <Modal opened={showAddModal} onClose={() => setShowAddModal(false)} title="Add Promoter">
                        <AddCampaignPromoterForm campaignId={id}/>
                    </Modal>

                    <Modal opened={showMessageModal} onClose={() => setShowMessageModal(false)} title="Message Promoter">
                        <MessagePromoter team={selectedTeam as ICampaignTeam}/>
                    </Modal>

                    {
                        /*
                    selectedTeam && (
                        <Modal opened={showEditModal} onClose={() => setShowEditModal(false)} title="Edit Promoter">
                            <EditAdminForm team={selectedTeam} />
                        </Modal>
                    )*/
                    
                    }
                </>
            )}
        </Box>
    );
};

export default ManageTeamPage;
