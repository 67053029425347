import React, { useEffect, useState } from 'react';
import { ActionIcon, Popover, Text, Avatar, Group, Badge, ScrollArea, Divider, Loader, Notification } from '@mantine/core';
import { IconAlertCircle, IconBell, IconUser } from '@tabler/icons-react';
import { formatDistanceToNow } from 'date-fns';
import { isErrorObject } from '../utils/utils';
import { makeGetRequest, makePutRequest } from '../services/httpHelper';
import ErrorHandler from '../services/ErrorHandler';

interface Notification {
  id: string;
  sender: string;
  avatar: string | null;
  message: string;
  receiver_id?: string | null;
  createdAt: string; // ISO date format
  read: boolean;
}

const ICON_SIZE = 24;

const NotificationIcon: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const [opened, setOpened] = useState(false);
  const [expandedNotifications, setExpandedNotifications] = useState<string | null>(null); // Track which notification is expanded

  const unreadCount = notifications.filter((n) => !n.read).length;

  const markAsRead = async (index: number, id: string) => {
    const res = await makePutRequest(`notification/${id}/read`, {});
    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      const updatedNotifications = [...notifications];
      updatedNotifications[index].read = true;
      setNotifications(updatedNotifications);
    }
  };

  const toggleExpand = (id: string) => {
    setExpandedNotifications((prevId) => (prevId === id ? null : id)); // Toggle the expanded state
  };

  const fetchNotifications = async () => {
    setIsloading(true);
    const res = await makeGetRequest(`notifications/${user.id}`);

    if (isErrorObject(res)) {
      setIsloading(false);
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      const results = res.data;
      setNotifications(results);
      setIsloading(false);
      setError(null);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Popover opened={opened} onClose={() => setOpened(false)} position="bottom" width={300} withArrow shadow="md">
      {isLoading && <Loader />}
      <Popover.Target>
        <ActionIcon variant="filled" color="blue" onClick={() => setOpened(!opened)}>
          <IconBell size={ICON_SIZE} />
          {unreadCount > 0 && (
            <Badge size="xs" color="red" variant="filled" style={{ position: 'absolute', top: 0, right: -10 }}>
              {unreadCount}
            </Badge>
          )}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown
        sx={{
          width: '300px',
          height: '100vh',
          maxHeight: '100vh',
          overflowY: 'auto',
        }}
      >
        <ScrollArea sx={{ height: '100%' }}>
          {error && (
            <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
              {error}
            </Notification>
          )}
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div key={index} onClick={() => { markAsRead(index, notification.id); toggleExpand(notification.id); }} style={{ padding: '10px 0', cursor: 'pointer' }}>
                <Group>
                  <Avatar src={notification.avatar || undefined} alt={notification.sender} size="sm">
                    {!notification.avatar && <IconUser size={24} />}
                  </Avatar>
                  <div>
                    <Text weight={notification.read ? 400 : 700}>{notification.sender}</Text>
                    <Text size="sm">
                      {expandedNotifications === notification.id
                        ? notification.message // Display full message if expanded
                        : notification.message.length > 30
                        ? `${notification.message.substring(0, 30)}...`
                        : notification.message}
                    </Text>
                    <Text size="xs" color="dimmed">
                      {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                    </Text>
                  </div>
                </Group>
                <Divider my="xs" />
              </div>
            ))
          ) : (
            <Text size="sm" color="dimmed">No new notifications</Text>
          )}
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  );
};

export default NotificationIcon;
