import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { IRole } from '../types';

interface ProtectedRouteProps {
  isAllowed: boolean;
  requiredRoles?: string[];
  redirectPath?: string;
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  requiredRoles = [],
  redirectPath = '/login',
  children
}) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const token = sessionStorage.getItem('token');

  // If user or token is missing, redirect
  if (!user || !token) {
   // return <Navigate to={redirectPath} replace />;
    return <Navigate to={redirectPath} replace state={{ from: location.pathname }} />;

  }

  const sessionExpired = tokenIsExpired(token);
  if (!isAllowed || sessionExpired) {
    alert('redirecting..')
    return <Navigate to={redirectPath} replace />;
  }

  // Get user roles from user object
  const userRoles = user.roles.length ? user.roles.map((role:IRole)=>role.name) : [];

  // Check if any user role matches required roles
  const hasRequiredRole = requiredRoles.length === 0 || userRoles.some((role: string) => requiredRoles.includes(role));

  // If no matching roles, redirect
  if (!hasRequiredRole) {
    return <Navigate to={redirectPath} replace />;
  }

  // If all checks pass, render the children or the next route
  return children ? <>{children}</> : <Outlet />;


};

// Function to check if the token is expired
const tokenIsExpired = (token: string): boolean => {
  const now = Date.now();
  const decoded: { exp: number } = jwtDecode<{ exp: number }>(token);

  return decoded.exp * 1000 < now;
}


export default ProtectedRoute;
