import {AppShell, useMantineTheme} from '@mantine/core';
import {Outlet} from 'react-router-dom'
import AdminAppNavbar from '../components/AdminAppNavbar';

const AdminDashboardLayout = () => {
    const theme = useMantineTheme();

    return (
        <>
            <AppShell
                styles={{
                    main: {
                        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                    },
                }}
                navbarOffsetBreakpoint="sm"
                asideOffsetBreakpoint="sm"
                navbar={<></>}
                header={<AdminAppNavbar/>}
            >
                <Outlet/>
            </AppShell>
        </>
    );
}

export default AdminDashboardLayout;
