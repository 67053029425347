import React, { useEffect, useState } from 'react';
import { Button, Modal, Group, Menu, Loader, ActionIcon, Avatar, Badge, Notification } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconTrash, IconLock, IconMessage, IconBarrierBlock, IconDotsVertical, IconUser, IconPlus, IconMinus, IconAlertCircle } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { IUser, IRole } from '../types';
import { isErrorObject, toTitleCase } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../services/httpHelper';

const ManageUsersPage: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [availableRoles, setAvailableRoles] = useState<IRole[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState<IUser[]>([]);

  const PAGE_SIZE = 10;

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    const assignableroles = roles.filter(role => !selectedUser?.roles.includes(role));
    setAvailableRoles(assignableroles)
  }, []);

  useEffect(()=>{
    const assignableroles = roles.filter(role => !selectedUser?.roles.includes(role));
    setAvailableRoles(assignableroles)


  },[selectedUser])

  // Fetch users
  const fetchUsers = async () => {
    setIsLoading(true);
    const res = await makeGetRequest(`users`);
    setIsLoading(false);
    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      setUsers(res.data);
      setRecords(res.data.slice(0, PAGE_SIZE));
    }
  };

  // Fetch roles
  const fetchRoles = async () => {
    const res = await makeGetRequest(`roles`);
    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
    } else {
      setRoles(res.data);
    }
  };

  // Block/Unblock user
  const handleBlockUser = async (userId: string, isBlocked: boolean) => {
    const confirm = window.confirm('Are sure about this?');
    if (confirm) {
      const res = await makePostRequest(`user/${userId}/block`, { blocked: !isBlocked });
      if (isErrorObject(res)) {
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
        fetchUsers();
        showNotification({ message: `User ${isBlocked ? 'unblocked' : 'blocked'} successfully`, color: 'green' });
      }
    }
  };

  // Remove user
  const handleRemoveUser = async (userId: string) => {
    const confirm = window.confirm('Are sure you want to permanently delete this user account?');
    if (confirm) {
      const res = await makeDeleteRequest(`user/${userId}`);
      if (isErrorObject(res)) {
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
      } else {
        fetchUsers();
        showNotification({ message: 'User removed successfully', color: 'green' });
      }
    }
  };

  // Send message to user
  const handleMessageUser = (user: IUser) => {
    showNotification({ message: `Message sent to ${user.first_name} ${user.last_name}`, color: 'green' });
  };

  // Manage roles (open modal)
  const handleManagerRole = (user: IUser) => {
    setSelectedUser(user);
    setOpened(true); // Open the modal
  };

  // Revoke role from user
  const revokeRole = async(roleId: string, userId: string) => {

    const confirm = window.confirm('Are sure want to remoke the role from user?')
    try {

   if(confirm){
    const res = await makeDeleteRequest(`revoke-role/${roleId}/${userId}`);
    if (isErrorObject(res)) {
        const errorMessage = ErrorHandler(res);
        setError(errorMessage);
        showNotification({ message: `Role revoked`, color: 'green' });
        fetchUsers();

    } else {
      if (selectedUser) {
        const updatedRoles = selectedUser.roles.filter((role) => role.id !== roleId);
        setSelectedUser({ ...selectedUser, roles: updatedRoles });
      }
      fetchUsers();
  showNotification({ message: `Role assigned`, color: 'green' });
 
    }
   }
 
  } catch (error) {
    console.error('Failed to update roles', error);
    showNotification({ message: 'Failed to update roles', color: 'red' });
  }
  };

  // Assign role to user
const assignRole = async (roleId: string, userId: string) => {
  try {
    const roleExists = selectedUser?.roles.find((role) => role.id === roleId);
    
    // If role already exists, show an alert and stop further execution
    if (roleExists) {
      alert('Role already assigned to the user');
      return;  // Ensure no further code is executed
    }

    // Proceed with assigning the role
    const res = await makePostRequest(`assign-single-role`, { roleId: roleId, userId: userId });

    if (isErrorObject(res)) {
      const errorMessage = ErrorHandler(res);
      setError(errorMessage);
      fetchUsers();
      showNotification({ message: 'Failed to assign role', color: 'red' });
    } else {
      if (selectedUser) {
        const roleToAdd = roles.find((role) => role.id === roleId);
        if (roleToAdd) {
          // Add the new role to the user's roles
          setSelectedUser({ ...selectedUser, roles: [...selectedUser.roles, roleToAdd] });
        }
      }

      fetchUsers();
      showNotification({ message: `Role assigned`, color: 'green' });
    }
  } catch (error) {
    console.error('Failed to update roles', error);
    showNotification({ message: 'Failed to update roles', color: 'red' });
  }
};


  // Handle pagination
  const handlePageChange = (page: number) => {
    setPage(page);
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(users.slice(from, to));
  };

  return (
    <div>

         {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
      {isLoading && <Loader />}
      <Group position="apart">
        <h1>User Management</h1>
        <Button onClick={() => setOpened(true)}>Add New User</Button>
      </Group>

      <DataTable
        columns={[
          { accessor: 'first_name', title: 'First Name' },
          { accessor: 'last_name', title: 'Last Name' },
          { accessor: 'email', title: 'Email' },
          { accessor: 'mobile_number', title: 'Phone' },
          {
            accessor: 'roles',
            title: 'Roles',
            render: (row: IUser) => row.roles?.map((role) => role.name).join(', ') || 'No Role Assigned',
          },
          {
            accessor: 'actions',
            title: 'Actions',
            render: (row) => (
              <Menu>
                <Menu.Target>
                  <ActionIcon>
                    <IconDotsVertical size={20} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={row.blocked ? <IconBarrierBlock size={16} /> : <IconLock size={16} />}
                    onClick={() => handleBlockUser(row.id, row.blocked)}
                  >
                    {row.blocked ? 'Unblock' : 'Block'} User
                  </Menu.Item>
                
                  <Menu.Item
                    icon={<IconTrash size={16} />}
                    onClick={() => handleRemoveUser(row.id)}
                  >
                    Remove User
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconMessage size={16} />}
                    onClick={() => handleMessageUser(row)}
                  >
                    Message User
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconUser size={16} />}
                    onClick={() => handleManagerRole(row)}
                  >
                    Manage Roles
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
        records={records}
        totalRecords={users.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={handlePageChange}
        highlightOnHover
        verticalSpacing="sm"
      />

      {/* Manage Roles Modal */}
      <Modal opened={opened} onClose={() => setOpened(false)} title="Manage User Roles">
        {selectedUser && (
          <div>
            <Group>
              <Avatar src={selectedUser.avatar} size="lg" />
              <div>
                <h3>{`${selectedUser.first_name} ${selectedUser.last_name}`}</h3>
                <Badge color={selectedUser.is_admin ? 'green' : 'blue'}>
                  {selectedUser.is_admin ? 'Admin' : 'User'}
                </Badge>
              </div>
            </Group>

            <h4>Assigned Roles</h4>
            <div>
              {selectedUser.roles.map((role) => (
                <Group key={role.id} position="apart">
                  <span>{toTitleCase(role.name)}</span>
                  <ActionIcon color="red" onClick={() => revokeRole(role.id, selectedUser.id)}>
                    <IconMinus size={16} />
                  </ActionIcon>
                </Group>
              ))}
            </div>

            <h4>Available Roles</h4>
            <div>
              {availableRoles.map((role) => (
                <Group key={role.id} position="apart">
                  <span>{toTitleCase(role.name)}</span>
                  <ActionIcon color="green" onClick={() => assignRole(role.id, selectedUser.id)}>
                    <IconPlus size={16} />
                  </ActionIcon>
                </Group>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ManageUsersPage;
