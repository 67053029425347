import {
    Box,
    Button,
    Card,
    Container,
    createStyles,
    Flex,
    Group,
    Loader,
    Paper,
    PaperProps,
    rem,
    SimpleGrid,
    Stack,
    Text,
    Title,
    TitleProps
} from "@mantine/core";
import {
    IconArrowUpRight,
    IconFunction,
    IconPlus,
    IconReceipt2,
    IconTrophy
} from "@tabler/icons-react";
import { CampaignsTable, DonatorsTable } from "../components";
import { Helmet } from "react-helmet";
import { Link, Navigate } from "react-router-dom";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { ICampaign, IDonation, } from "../types";
import { useEffect, useState } from "react";
import { makeGetRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";

const useStyles = createStyles((theme) => ({
    root: {
        padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    value: {
        fontSize: rem(24),
        fontWeight: 700,
        lineHeight: 1,
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));

interface User {
    id:string;
    first_name: string;
    role: string;
    campaigns: Campaign[];
    donations: Donation[];
    is_admin:boolean
}

interface Campaign {
    id: string;
    title: string;
    donations: number;
    totalAmount: number;
    currency: string;
    active: boolean;
    donors: Donor[];
}

interface Donation {
    id: string;
    amount: number;
    currency: string;
    serviceFee: number;
    receivedCurrency: string;
    campaignId: string;
}

interface Donor {
    name: string;
    amount: number;
}
type AverageDonationSummary = {
    currentMonthAverage: number;
    previousMonthAverage: number;
    percentageDifference: number;
  };

const UserDashboardPage = () => {
    const { classes } = useStyles();

    const paperProps: PaperProps = {
        p: "md",
        shadow: "sm"
    }

    const subTitleProps: TitleProps = {
        size: 18,
        mb: "sm"
    }

    const user: User = JSON.parse(localStorage.getItem('user') || '{}');
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [donations, setDonations] = useState<IDonation[]>([]);
    const [averageSummary, setAverageSummary] = useState<AverageDonationSummary | null>(null);
    const [activeCampaigns, setActiveCampaigns] = useState<ICampaign[]>([]);

    //const totalDonations = user.donations.reduce((acc, donation) => acc + donation.amount, 0).toFixed(2);
    //const activeCampaigns = user.campaigns.filter(campaign => campaign.active).length;

    const totalDonations =():number=>{
        let total:number = 0;

        donations.map((donation:IDonation)=>{
            total = total+parseFloat(donation.donation_amount)
        })
        return total;

    }

    const calculateAverageDonationsPerCampaign = (donations: IDonation[]): AverageDonationSummary => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
      
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
        const filterByMonth = (date: Date, month: number, year: number) =>
          date.getMonth() === month && date.getFullYear() === year;
      
        const currentMonthDonations = donations.filter(donation =>
          filterByMonth(new Date(donation.createdAt), currentMonth, currentYear)
        );
      
        const previousMonthDonations = donations.filter(donation =>
          filterByMonth(new Date(donation.createdAt), previousMonth, previousMonthYear)
        );
      
        const calculateAverage = (donations: IDonation[]) => {
          const totalDonations = donations.reduce((total, donation) => total + parseFloat( donation.donation_amount), 0);
          const numberOfCampaigns = new Set(donations.map(donation => donation.campaign_id)).size;
          return numberOfCampaigns === 0 ? 0 : totalDonations / numberOfCampaigns;
        };
      
        const currentMonthAverage = calculateAverage(currentMonthDonations);
        const previousMonthAverage = calculateAverage(previousMonthDonations);
      
        const percentageDifference = previousMonthAverage === 0 
          ? (currentMonthAverage === 0 ? 0 : 100)
          : ((currentMonthAverage - previousMonthAverage) / previousMonthAverage) * 100;
      
        return {
          currentMonthAverage,
          previousMonthAverage,
          percentageDifference
        };
      };

      useEffect(() => {
        const fetchCampaigns = async () => {
            setIsLoading(true);
            const endpoint = `user-campaigns/${user.id}`;
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setCampaigns(res.data);
            }
            setIsLoading(false);
        };
    
     
    
        const fetchDonations = async () => {
            setIsLoading(true);
            const endpoint = `user-donations/${user.id}`;
            const res = await makeGetRequest(endpoint);
            if (isErrorObject(res)) {
                setIsLoading(false);
                const errorMessage = ErrorHandler(res);
                setError(errorMessage);
            } else {
                setDonations(res.data);
            }
            setIsLoading(false);
        };
    
        fetchCampaigns();
        fetchDonations();
    
    }, []); // Only run once on mount, not dependent on donations or campaigns
    
    // Separate effect to run calculations after donations and campaigns have been fetched
    useEffect(() => {
        if (donations.length && campaigns.length) {
            const avSummary = calculateAverageDonationsPerCampaign(donations);
            setAverageSummary(avSummary);
    
            const runningCampaigns = campaigns.filter((campaign) => campaign.status.toLocaleLowerCase() === 'active');
            setActiveCampaigns(runningCampaigns);
        }
    }, [donations, campaigns]); // Run only when donations or campaigns change
    

    if(user && user.is_admin){
        return <Navigate to="/admin-dashboard"/>

    }else{
        return (
            <>
                <Helmet>
                    <title>My Dashboard</title>
                </Helmet>
    
                {isLoading && <Loader/>}
                {error && <p className="error">{error}</p>}
                <Box>
                    <Container fluid my="xl">
                        <Stack spacing="xl">
                            <Title order={3}>Welcome Again, {toTitleCase(user.first_name)} {user.is_admin}</Title>
                            <SimpleGrid
                                cols={4}
                                breakpoints={[{ maxWidth: 'md', cols: 2, spacing: 'md' }, {
                                    maxWidth: 'sm',
                                    cols: 1,
                                    spacing: 'sm'
                                }]}
                            >
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Total Donations
                                        </Text>
                                        <IconReceipt2 className={classes.icon} size="1.4rem" stroke={1.5} />
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                    <Text className={classes.value}>${formatCurrency(parseFloat(totalDonations().toFixed(2)))}</Text>
                                        <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>Your donations todate</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5} />
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to previous month
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Total Campaigns
                                        </Text>
                                        <IconReceipt2 className={classes.icon} size="1.4rem" stroke={1.5} />
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>{campaigns && campaigns.length}</Text>
                                        
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to yesterday
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Average Donations per Campaign
                                        </Text>
                                        <IconFunction className={classes.icon} size="1.4rem" stroke={1.5} />
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>{averageSummary && averageSummary.currentMonthAverage.toFixed(2)}%</Text>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                        <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>{averageSummary && averageSummary.percentageDifference.toFixed(2)}%</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5}/>
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to previous month
                                    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                    <Group position="apart">
                                        <Text size="xs" color="dimmed" className={classes.title}>
                                            Active Campaigns
                                        </Text>
                                        <IconTrophy className={classes.icon} size="1.4rem" stroke={1.5} />
                                    </Group>
    
                                    <Group align="flex-end" spacing="xs" mt={25}>
                                        <Text className={classes.value}>{activeCampaigns && activeCampaigns.length}</Text>
                                        <Text color={10 > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
                                            <span>{(activeCampaigns.length/campaigns.length)*100}%</span>
                                            <IconArrowUpRight size="1rem" stroke={1.5} />
                                        </Text>
                                    </Group>
    
                                    <Text fz="xs" c="dimmed" mt={7}>
                                        Compared to total campaigns
                                    </Text>
                                </Paper>
                            </SimpleGrid>
                            <Paper {...paperProps}>
                                <Card.Section mb="lg">
                                    <Flex align="center" justify="space-between">
                                        <Box>
                                            <Title {...subTitleProps}>My Campaigns</Title>
                                            <Text size="sm">Let's manage your campaigns</Text>
                                        </Box>
                                        <Button
                                            leftIcon={<IconPlus size={18} />}
                                            component={Link}
                                            to="/create-campaign"
                                        >
                                            Create a Campaign
                                        </Button>
                                    </Flex>
                                </Card.Section>
                                <Card.Section>
                                  <CampaignsTable campaigns={campaigns} />
                                </Card.Section>
                            </Paper>
                            <Paper {...paperProps}>
                                <Card.Section>
                                    <Title {...subTitleProps}>Top Contributors</Title>
                                    {
                                        totalDonations()>0 ?
                                        <DonatorsTable campaigns={campaigns} />
                                        :
                                        <Text>No Donations</Text>
                                    }
                                   
                                </Card.Section>
                            </Paper>
                          
                        </Stack>
                    </Container>
                </Box>
            </>
        );
    }

  

   
};

export default UserDashboardPage;
